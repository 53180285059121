<template>
    <div class="container-parent d-flex align-center justify-center tw-h-full">
        <v-container>
            <h2 class="tw-font-extrabold tw-text-center tw-text-secondary tw-text-4xl tw-underline tw-underline-offset-0 tw-decoration-primary tw-mb-6">{{ $t('auth.forgotPassword.title1') }}</h2>
            <h2 class="tw-font-semibold tw-text-center tw-text-secondary tw-text-2xl tw-mb-20">{{ $t('auth.forgotPassword.subtitle1') }}</h2>
            <v-form>
                <v-text-field
                    class="tw-mb-8"
                    name="email"
                    variant="outlined"
                    :placeholder="$t('auth.forgotPassword.email')"
                    :rules="[(v) => rules.required(v, $t('auth.forgotPassword.requiredField')), (v) => rules.email(v, $t('auth.forgotPassword.invalidEmail'))]"
                    type="email"
                    hide-details="auto"
                    v-model="email" />
                <div
                    class="tw-flex tw-justify-end tw-z-10 tw-mt-[-12px] !tw-mb-4"
                    v-show="error">
                    <v-alert
                        class="!tw-justify-start"
                        dense
                        variant="tonal"
                        type="error"
                        >{{ $t('auth.forgotPassword.error') }}</v-alert
                    >
                </div>
                <div
                    class="tw-flex tw-justify-end tw-z-10 tw-mt-[-0.75rem] !tw-mb-4"
                    v-show="success">
                    <v-alert
                        class="!tw-justify-start"
                        dense
                        variant="tonal"
                        type="success"
                        >{{ $t('auth.forgotPassword.successText')
                        }}<router-link
                            to="/auth/login"
                            class="tw-font-bold"
                            >{{ $t('auth.forgotPassword.back') }}</router-link
                        >.</v-alert
                    >
                </div>
                <div class="tw-flex tw-justify-center tw-mb-12">
                    <v-btn
                        :loading="isLoading"
                        type="submit"
                        class="bg-primary text-black font-weight-bold !tw-px-12 !tw-max-h-none !tw-h-auto !tw-py-4"
                        rounded="xl"
                        size="x-large"
                        color="primary"
                        @click.prevent="submitForgotPassword()">
                        {{ $t('auth.forgotPassword.submit') }}
                    </v-btn>
                </div>
            </v-form>
            <div class="tw-flex tw-text-center tw-justify-center tw-w-14 tw-mx-auto tw-gap-x-4">
                <span
                    class="fi fi-pt hover:tw-cursor-pointer"
                    @click="setLanguage('pt')"></span>
                <span
                    class="fi fi-gb hover:tw-cursor-pointer"
                    @click="setLanguage('en')"></span>
            </div>
        </v-container>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { forgotPassword } from '@/api/auth';
    import { useI18n } from 'vue-i18n';
    import { useLanguageStore } from '@/store/modules/language';
    import rules from '@/composables/rules';

    const store = useLanguageStore();
    const i18n = useI18n();

    const { t: $t } = useI18n();

    const email = ref('');

    const isLoading = ref(false);
    const error = ref(false);
    const success = ref(false);

    const setLanguage = (language: string) => {
        i18n.locale.value = language;
        store.setLanguage(language);
    };

    setLanguage(store.language);

    const submitForgotPassword = async () => {
        isLoading.value = true;
        try {
            await forgotPassword({ email: email.value });
            isLoading.value = false;
            success.value = true;
        } catch (err) {
            isLoading.value = false;
            error.value = true;
            setTimeout(() => {
                error.value = false;
            }, 4000);
        }
    };
</script>

<style scoped>
    .container-parent {
        max-width: min(90%, 31.25rem);
        margin-inline: auto;
    }
    h2.tw-underline {
        text-decoration-skip-ink: none;
    }
</style>
