<template>
    <div class="container-parent d-flex align-center justify-center tw-h-full">
        <v-container>
            <h2 class="tw-font-extrabold tw-text-center tw-text-secondary tw-text-4xl tw-underline tw-underline-offset-0 tw-decoration-primary tw-mb-6">{{ $t('auth.login.title1') }}</h2>
            <h2 class="tw-font-semibold tw-text-center tw-text-secondary tw-text-2xl tw-mb-20">{{ $t('auth.login.subtitle1') }}</h2>
            <v-form>
                <v-text-field
                    class="tw-mb-8"
                    name="email"
                    variant="outlined"
                    :placeholder="$t('auth.login.emailOrNif')"
                    :rules="[(v) => rules.required(v, $t('auth.login.requiredField')), (v) => rules.emailOrNif(v, $t('auth.login.invalidEmailOrNif'))]"
                    type="email"
                    hide-details="auto"
                    v-model="emailOrNIF" />
                <v-text-field
                    class="tw-mb-6"
                    name="password"
                    variant="outlined"
                    :placeholder="$t('auth.login.password')"
                    :rules="[(v) => rules.required(v, $t('auth.login.requiredField')), (v) => rules.min(v, 6, $t('auth.login.invalidPasswordLength'))]"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append-inner="isPasswordVisible = !isPasswordVisible"
                    v-model="password"></v-text-field>
                <div
                    class="tw-flex tw-justify-end tw-z- tw-mb-2"
                    v-show="error">
                    <v-alert
                        class="!tw-justify-start"
                        dense
                        variant="tonal"
                        type="error"
                        >{{ $t('auth.login.errorText') }}</v-alert
                    >
                </div>
                <a
                    href="/auth/forgot-password"
                    class="tw-font-semibold tw-text-secondary tw-text-sm tw-underline tw-flex tw-justify-end tw-mb-14"
                    >{{ $t('auth.login.forgotPassword') }}</a
                >
                <div class="tw-flex tw-justify-center tw-mb-20">
                    <v-btn
                        :loading="isLoading"
                        type="submit"
                        class="bg-primary text-black font-weight-bold !tw-px-12 !tw-max-h-none !tw-h-auto !tw-py-4"
                        rounded="xl"
                        size="x-large"
                        color="primary"
                        @click.prevent="submitLogin()">
                        {{ $t('auth.login.login') }}
                    </v-btn>
                </div>
                <!-- <p class="tw-text-sm tw-font-semibold tw-text-center tw-text-secondary">
                    {{ $t('auth.login.dontYouHaveAnAccount')
                    }}<a
                        class="tw-text-sm tw-font-bold tw-text-center tw-text-secondary"
                        href="/auth/register"
                        >{{ $t('auth.login.register') }}</a
                    >
                </p> -->
            </v-form>
            <div class="tw-flex tw-text-center tw-justify-center tw-w-14 tw-mx-auto tw-mt-4 tw-gap-x-4">
                <span
                    class="fi fi-pt hover:tw-cursor-pointer"
                    @click="setLanguage('pt')"></span>
                <span
                    class="fi fi-gb hover:tw-cursor-pointer"
                    @click="setLanguage('en')"></span>
            </div>
        </v-container>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { login } from '@/api/auth';
    import { useAuthStore } from '@/store/modules/auth';
    import { useI18n } from 'vue-i18n';
    import { useLanguageStore } from '@/store/modules/language';
    import { useRouter } from 'vue-router';
    import rules from '@/composables/rules';

    const i18n = useI18n();
    const store = useLanguageStore();

    const { t: $t } = useI18n();

    const emailOrNIF = ref('');
    const password = ref('');
    const isPasswordVisible = ref(false);

    const isLoading = ref(false);
    const error = ref(false);

    const setLanguage = (language: string) => {
        i18n.locale.value = language;
        store.setLanguage(language);
    };

    setLanguage(store.language);

    const $router = useRouter();

    const submitLogin = () => {
        isLoading.value = true;
        if (!emailOrNIF.value || !password.value) {
            return;
        }
        login({
            identifier: emailOrNIF.value,
            password: password.value,
        })
            .then((response) => {
                useAuthStore().setToken(response.data.jwt);
                useAuthStore().setUser(response.data.user);
                useAuthStore().setIsAuthenticated(true);
                isLoading.value = false;
                if (response.data.user.role.name === 'Admin') $router.push({ name: 'UsersAdmin' });
                else $router.push({ name: 'Investment' });
            })
            .catch((err) => {
                isLoading.value = false;
                error.value = true;
                setTimeout(() => {
                    error.value = false;
                }, 4000);
            });
    };
</script>

<style scoped>
    .container-parent {
        max-width: min(90%, 31.25rem);
        margin-inline: auto;
    }
    h2.tw-underline {
        text-decoration-skip-ink: none;
    }
</style>
